import {
  Divider,
  Stack,
  Text,
  Container,
  Box,
  HStack,
} from "@chakra-ui/react";
import ProfileArray from "./ProfileArray";

export default function About({ color }) {
  const profile = ProfileArray();
    return (
      <>
        <Container maxW={"3xl"} id="about">
          <Stack
            as={Box}
            textAlign={"center"}
            spacing={{ base: 8, md: 14 }}
            pb={{ base: 20, md: 36 }}
          >
            <Stack align="center" direction="row" px={4}>
              <HStack mx={4}>
                <Text color={`${color}.400`} fontWeight={800}>
                  01
                </Text>
                <Text fontWeight={800}>About</Text>
              </HStack>
              <Divider orientation="horizontal" />
            </Stack>
            <Text color={"gray.600"} fontSize={"xl"} px={4}>
              <p>Hey there! If you're reading this, you've stumbled upon my small space on the internet. Allow me to introduce myself:</p><br/>
              <p>- 🔥 I'm a passionate self-taught developer with a deep love for all things IT.</p><br/>
              <p>- 🐍 Fluent in Python, Django, FastAPI and all things that come with it, such as MongoDB, redis, fly.io, postgres.</p><br/>
              <p>- 💻 An enthusiastic blockchain developer who speaks fluently in Solidity.</p><br/>
              <p>- 🧱 Constantly exploring the intricate connections between backend systems and the blockchain aspect of dApps.</p><br/>
              <p>Currently, my focus revolves around the development of <a href="https://nagano.io">nagano.io</a>, an exciting platform aiming to provide interactive games and activities for the sports industry.</p>
            </Text>
          </Stack>
        </Container>
      </>
    );
}

